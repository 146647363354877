<template>
  <div>
    
    <v-skeleton-loader :loading="firstLoad" :transition="transition" :tile="true" type="table">
      
      <v-data-table
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="totalEntities"
        :loading="loading"
        :search="search"
        item-key="id"
        dense
      >

        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{new Date(item.createdAt).toLocaleString()}}</span>
        </template>
        <template v-slot:[`item.languageId`]="{ item }">

          <v-chip
            class="ma-2"            
            label
            small
          >
          
          
            <country-flag :country='getFlagFromLangCode(item.languageId)' size='small'/>
            <span class="ml-2">{{item.languageId}}</span>
          </v-chip>


         
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          <span>{{new Date(item.updatedAt).toLocaleString()}}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{schema.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="getDataFromApiDebounced"
              @input="getDataFromApiDebounced"
            ></v-text-field>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>You can use % as a wildcard</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              @click="newItem"
            >
              <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ $vuetify.lang.t('$vuetify.addNew') }}</v-btn>

            <v-dialog
              v-model="dialog"              
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              :retain-focus="false"
            >
              
              <v-card flat>
      <v-toolbar flat dark color="primary">
        <v-container fluid>
          <v-row align="center" justify="space-between">
            <v-col>
              <v-toolbar-title>{{ isInsert ? $vuetify.lang.t('$vuetify.newItem') : $vuetify.lang.t('$vuetify.editItem')}}</v-toolbar-title>
            </v-col>
            <v-col class="text-right">
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>

      <v-container>        
        <ValidationObserver ref="observer" v-slot="{ handleSubmit/*, invalid,errors*/ }">
          <form @submit.prevent="handleSubmit(save)" v-if="form_loaded" novalidate>
            <v-card-actions>
              <v-btn text color="secondary" @click="close">
                <v-icon left>mdi-backspace-outline</v-icon>
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="secondary" type="submit">
                <v-icon left>mdi-content-save</v-icon>
                {{ $vuetify.lang.t('$vuetify.save') }}
              </v-btn>
            </v-card-actions>

            <v-card-text>
              
              <ValidationProvider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.resourceKey')" rules="required|max:1024">
                  
                <v-text-field
                    v-model="editedItem.resourceKey"
                    :label="$vuetify.lang.t('$vuetify.resourceKey')"
                    required="required"
                    :error-messages="errors"
                    counter="true" 
                    :disabled="!isInsert"
                ></v-text-field>

              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.resourceSet')" rules="max:512">
                  
                <v-text-field
                    v-model="editedItem.resourceSet"
                    :label="$vuetify.lang.t('$vuetify.resourceSet')"
                    
                    :error-messages="errors"
                    counter="true"
                    :disabled="!isInsert"
                ></v-text-field>

              </ValidationProvider>     
                  
              <v-tabs dark background-color="primary" v-model="tab" v-if="form_loaded">
                  <v-tab                    
                    :key="editedItem.languageId"
                  ><country-flag :country='getFlagFromLangCode(editedItem.languageId)' size='normal'/><span class="ml-2">{{ editedItem.languageId }}</span></v-tab>
                  <v-tab
                    v-for="item in editedItem.otherLanguages"
                    :key="item.languageId"
                  ><country-flag :country='getFlagFromLangCode(item.languageId)' size='normal'/><span class="ml-2">{{ item.languageId }}</span></v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" v-if="form_loaded">
                  <v-tab-item
                    :key="editedItem.languageId"
                  >
                    <ValidationProvider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.value')" rules="max:512">
                 
                      <v-textarea
                          v-model="editedItem.value"
                          :label="$vuetify.lang.t('$vuetify.value')"
                          
                          :error-messages="errors"
                          counter="true"
                          auto-grow
                          filled
                          background-color="amber lighten-4"
                      ></v-textarea>

                    </ValidationProvider>
                  </v-tab-item>
                  <v-tab-item
                    v-for="item in editedItem.otherLanguages"
                    :key="item.languageId"
                  >
                    <ValidationProvider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.value')" rules="max:512">
                 
                        <v-textarea
                    v-model="item.value"
                    :label="$vuetify.lang.t('$vuetify.value')"
                    
                    :error-messages="errors"
                    counter="true"
                    auto-grow
                    filled
                    background-color="amber lighten-4"
                ></v-textarea>
                    </ValidationProvider>
                     
                  </v-tab-item>
                </v-tabs-items>
                
              

              
            </v-card-text>

            <v-card-actions class="pt-8">
              <v-btn text color="secondary" @click="close">
                <v-icon left>mdi-backspace-outline</v-icon>
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="secondary" type="submit">
                <v-icon left>mdi-content-save</v-icon>
                {{ $vuetify.lang.t('$vuetify.save') }}
              </v-btn>
            </v-card-actions>

            
          </form>
        </ValidationObserver>
      </v-container>
    </v-card>
            </v-dialog>
            
          </v-toolbar>
        </template>
        
        <template v-slot:[`item.actions`]="{ item }">
          
          
          <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </div>
</template>


<script>
import { baseApiUrl,apiCall } from "../utils/api";
import { getFlagFromLangCode } from "../utils/flags";
//import PrettyPrint from "../components/pretty-print";
import _ from 'lodash';

export default {
  name: "Resources",
  props: {
    schemaKey: {
      type: String,
      default: ""
    }
  },
  components: {
    //"pretty-print": PrettyPrint,    
  },
  data() {
    return {
      tab: null,
      selected_language: "it",
      blockModal: false,
      dialog: false,
      totalEntities: 0,
      schema: {},
      entities: [],
      firstLoad: true,
      loading: true,
      form_loaded: false,
      transition: "scale-transition",
      options: {
        page : 1,
        itemsPerPage : 10,
        sortBy: ["createdAt"],
         sortDesc: [true]
      },
      editedItem: {},
      defaultItem: {
        languageId: "it",
        resourceKey: "",
        resourceSet:"",
        satus: "TRANSLATED",
        webSiteId: ""
      },  
      canReorder: false,    
      editedIndex: -1,
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.resourceKey'),
          align: "start",
          sortable: true,
          value: "resourceKey"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.languageId'),
          align: "start",
          sortable: true,
          value: "languageId"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.resourceSet'),
          align: "start",
          sortable: true,
          value: "ResourceSet"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.createdAt'),
          align: "start",
          sortable: true,
          value: "createdAt"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.updatedAt'),
          align: "start",
          sortable: true,
          value: "updatedAt"
        },
        { text: this.$vuetify.lang.t('$vuetify.actions'), value: "actions", sortable: false }
      ],
      state: {},
      isInsert: false,
      search: ''
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApiDebounced();
      },
      deep: true,
      immediate: false
    }
  },
  computed: {
    // a computed getter
    schemaConfiguration: function() {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configuration);
    },
    schemaLocalizationConfiguration: function() {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configurationLocalization);
    },
    /*isInsert: function() {
      return this.editedIndex == -1;
    },*/
    isUpdate: function() {
      return !this.isInsert;// this.editedIndex > -1;
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    },
    currentWebSiteObj() {
      return this.$store.getters.getCurrentWebSiteObj;
    }/*,
    canReorder() {
      return this.schemaConfiguration?.dataTable?.canReorder;
    },*/
  },
  mounted() {
    this.getDataFromApiDebounced();
  },
  beforeUpdate() {
    
  },
  methods: {
    onChangeState(value) {
      this.state = value;
    },
    getFlagFromLangCode(langCode)
    {
      return getFlagFromLangCode(langCode);
    },
    getDataFromApi() {
      return new Promise((resolve, reject) => {       

            this.loading = true;
            const {
                  sortBy, sortDesc,
                  page,
                  itemsPerPage
                } = this.options;

            let filters = {
              WebSiteId: this.currentWebSite,
              Page: page,
              Limit: itemsPerPage,
              SortBy: Array.isArray(sortBy) ? sortBy : [...sortBy],
              SortDesc: Array.isArray(sortDesc) ? sortDesc : [...sortDesc],
              GenericSearch: '%'+this.search+'%'
            };
            apiCall({ url: "ResourceLocalizations/paged", data: filters, method: "GET" })
              .then(resp => {
                this.firstLoad = false;
                //console.log("Paged Results Response",resp);
                let items = resp.results;
                const total = resp.rowCount;

                

                //console.log("items", items);

                this.loading = false;
                resolve({
                  items,
                  total
                });
              })
              .catch(err => {
                reject(err);
              });
          
          });
     
    },    
    editItem(item) {
      this.form_loaded = false;
      //console.log(this.currentWebSiteObj);
      this.editedIndex = this.entities.indexOf(item);
      this.isInsert = false;
      this.tab = 0;

      //console.log("item",item,"this.editedIndex",this.editedIndex,"this.isUpdate",this.isUpdate);

      apiCall({ url: "ResourceLocalizations/" + item.id, data: {}, method: "GET" })
        .then(resp => {
          
          this.entities[this.editedIndex] = resp;

          item = this.entities[this.editedIndex];

          let filters = {
              WebSiteId: this.currentWebSite,
              Page: 0,
              Limit: 1000,
              ResourceKey: item.resourceKey
            };
            apiCall({ url: "ResourceLocalizations", data: filters, method: "GET" })
              .then(respOther => {

                var otherLanguages = [];

                //ciclo le lingue per vedere se ci sono elementi altrimenti li creo
                this.currentWebSiteObj.webSiteLanguages.forEach((lang) => {
                  //console.log(lang.languageId , this.editedItem.languageId,lang.languageId != this.editedItem.languageId);
                  if(lang.languageId != item.languageId)
                  {
                    var res = respOther.find((l) => {return l.languageId == lang.languageId;});
                  
                    if(res)
                    {
                      otherLanguages.push(res);
                    }
                    else
                    {
                      var tempRes = Object.assign({}, item,{id: "",languageId: lang.languageId});
                      otherLanguages.push(tempRes);
                    }
                  }
                });

                
                item = Object.assign({}, item,{otherLanguages: otherLanguages});

                //console.log("otherLanguages",item);

                this.editedItem = item;
                this.form_loaded = true;
                this.dialog = true;
                
              })
              .catch(err => {
                err => {}
              });

          

          
        })
        .catch(err => {});
    },
    newItem() {
      this.form_loaded = false;
      this.editedIndex = -1;
      this.isInsert = true;
      this.tab =0;

      let webSiteId = this.$store.getters.getCurrentWebSite;

      var item = Object.assign({}, this.defaultItem);

      var otherLanguages = [];

      //ciclo le lingue per vedere se ci sono elementi altrimenti li creo
      this.currentWebSiteObj.webSiteLanguages.forEach((lang) => {
        //console.log(lang.languageId , this.editedItem.languageId,lang.languageId != this.editedItem.languageId);
        if(lang.languageId != item.languageId)
        {
          var tempRes = Object.assign({}, item,{languageId: lang.languageId});
          otherLanguages.push(tempRes);
        }
      });

      
      item = Object.assign({}, item,{otherLanguages: otherLanguages});


      this.editedItem = item;
      this.form_loaded = true;
      this.dialog = true;
        
    },
    deleteItem(item) {
      this.$confirm("Cancellare l'elemento corrente?").then(res => {
        //console.log(res);
        if (res) {
          this.blockModal = true;
          apiCall({
            url: "ResourceLocalizations/" + item.id,
            data: {},
            method: "DELETE"
          })
            .then(resp => {
              this.editedItem = -1;

              this.getDataFromApi().then(data => {
                this.entities = data.items;
                this.totalEntities = data.total;
                this.blockModal = false;
              });
            })
            .catch(err => {
              this.blockModal = false;
            });
        }
      });
    },
    onDialogClosed() {
      this.dialog = false;
      this.blockModal = false;
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    onSaved() {
      //console.log(this.editedItem);
      
      this.getDataFromApi().then(data => {
        this.entities = data.items;
        this.totalEntities = data.total;
        this.onDialogClosed();
        this.blockModal = false;
      });
    },
    getDataFromApiDebounced: _.debounce(function() {
          this.getDataFromApi().then(data => {
            this.entities = data.items;
            this.totalEntities = data.total;
          }).catch(e => {
            console.error(e);
          });   
      }, 1000),
    close() {
      this.dialog = false;
    },
    save() {
      //console.log("editedItem", this.editedItem, "IsInsert", this.isInsert);
      this.blockModal = true;

      this.defaultItem.webSiteId = this.currentWebSite;
      this.editedItem.webSiteId = this.currentWebSite;
      let savePromises = [];
      if(this.isInsert) delete this.editedItem.id;
      savePromises.push(
        apiCall({
          url: "ResourceLocalizations" + (this.isInsert ? "" : "/" + this.editedItem.id),
          data: Object.assign({}, this.defaultItem,this.editedItem),
          method: this.isInsert ? "POST" : "PUT",
        })
      );

      

      this.editedItem.otherLanguages.forEach(element => {
        //console.log(element);
        let insert = !element.id || element.id == "";
        element.webSiteId = this.currentWebSite;
        let el = Object.assign({}, this.editedItem,element);
        el.resourceKey = this.editedItem.resourceKey;
        el.resourceSet = this.editedItem.resourceSet;

        if(insert) {
          delete element.id;
          delete el.id;
        }
        el.value = el.value || this.editedItem.value || "";
        savePromises.push(
          apiCall({
            url: "ResourceLocalizations" + ( insert ? "" : "/" + element.id),
            data: el,
            method: insert ? "POST" : "PUT",
          })
        );
      });



      Promise.all(savePromises)
        .then((resp) => {
          this.getDataFromApi().then(data => {
            this.entities = data.items;
            this.totalEntities = data.total;
            this.onDialogClosed();
          });         
          
        })
        .catch((err) => {
          this.blockModal = false;
        });
    }
  }
};
</script>