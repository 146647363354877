var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{attrs:{"loading":_vm.firstLoad,"transition":_vm.transition,"tile":true,"type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.totalEntities,"loading":_vm.loading,"search":_vm.search,"item-key":"id","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))])]}},{key:"item.languageId",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","small":""}},[_c('country-flag',{attrs:{"country":_vm.getFlagFromLangCode(item.languageId),"size":"small"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.languageId))])],1)]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString()))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.schema.name))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"change":_vm.getDataFromApiDebounced,"input":_vm.getDataFromApiDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v("You can use % as a wildcard")])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.newItem}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.addNew')))],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.isInsert ? _vm.$vuetify.lang.t('$vuetify.newItem') : _vm.$vuetify.lang.t('$vuetify.editItem')))])],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.form_loaded)?_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-backspace-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel'))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.save'))+" ")],1)],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.resourceKey'),"rules":"required|max:1024"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.resourceKey'),"required":"required","error-messages":errors,"counter":"true","disabled":!_vm.isInsert},model:{value:(_vm.editedItem.resourceKey),callback:function ($$v) {_vm.$set(_vm.editedItem, "resourceKey", $$v)},expression:"editedItem.resourceKey"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.resourceSet'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.resourceSet'),"error-messages":errors,"counter":"true","disabled":!_vm.isInsert},model:{value:(_vm.editedItem.resourceSet),callback:function ($$v) {_vm.$set(_vm.editedItem, "resourceSet", $$v)},expression:"editedItem.resourceSet"}})]}}],null,true)}),(_vm.form_loaded)?_c('v-tabs',{attrs:{"dark":"","background-color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:_vm.editedItem.languageId},[_c('country-flag',{attrs:{"country":_vm.getFlagFromLangCode(_vm.editedItem.languageId),"size":"normal"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.editedItem.languageId))])],1),_vm._l((_vm.editedItem.otherLanguages),function(item){return _c('v-tab',{key:item.languageId},[_c('country-flag',{attrs:{"country":_vm.getFlagFromLangCode(item.languageId),"size":"normal"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.languageId))])],1)})],2):_vm._e(),(_vm.form_loaded)?_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:_vm.editedItem.languageId},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.value'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.value'),"error-messages":errors,"counter":"true","auto-grow":"","filled":"","background-color":"amber lighten-4"},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", $$v)},expression:"editedItem.value"}})]}}],null,true)})],1),_vm._l((_vm.editedItem.otherLanguages),function(item){return _c('v-tab-item',{key:item.languageId},[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.value'),"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.value'),"error-messages":errors,"counter":"true","auto-grow":"","filled":"","background-color":"amber lighten-4"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)})],1)})],2):_vm._e()],1),_c('v-card-actions',{staticClass:"pt-8"},[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-backspace-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel'))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.save'))+" ")],1)],1)],1):_vm._e()]}}])})],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }